export function labelsToTree (list) {
  const map = {}

  list = list.map(item => {
    const { name, description, id, color } = item
    let newName
    const nameArray = name.split(' ')
    const nameArrayLength = nameArray.length
    if (nameArrayLength >= 2) {
      item.parentName = nameArray[nameArrayLength - 2]
      newName = nameArray[nameArrayLength - 1]
    } else {
      newName = name
    }

    const descriptionArray = (description || '').split('|')

    item.icon = descriptionArray[0]
    item.index = descriptionArray[1]
    item.name = newName
    item.type = 'category'
    item.color = '#' + color

    map[newName] = item
    map[id] = item
    return item
  })
  // 排序
  list.sort((a, b) => {
    return a.index > b.index ? 1 : -1
  })

  const tree = []
  for (const item of list) {
    if (map[item.parentName]) {
      if (!map[item.parentName]?.children) {
        map[item.parentName].children = [item]
      } else {
        map[item.parentName].children.push(item)
      }
    } else if (!item.parentName) {
      tree.push(item)
    }
  }

  return { tree, map }
}

export function issuesParse (data) {
  data = data.filter(item => item.author_association === 'OWNER').map(item => {
    const { body, title: name, labels, id, created_at: time, number, reactions } = item

    const bodyMap = new Map(body.split('\n').map(item => {
      item = item.trim()
      const match = item.match(/([^:]*): (.*)/)
      if (!match || !match[1] || !match[2]) return false
      return [match[1], match[2]]
    }).filter(Boolean))

    return {
      name,
      body,
      id,
      categoryId: labels[0] && labels[0].id,
      categoryName: labels[0] && labels[0].name,
      url: (bodyMap.get('链接') || '').trim(),
      logo: (bodyMap.get('图标') || '').trim(),
      desc: (bodyMap.get('说明') || '').trim(),
      index: bodyMap.get('排序'),
      time,
      number
    }
  })

  // index正序 + 时间倒序
  return data.sort((a, b) => {
    const indexA = a.index
    const indexB = b.index
    const timeA = a.time
    const timeB = b.time

    if (indexA && indexB) return indexA > indexB ? 1 : -1
    if (!indexA && !indexB) return timeA > timeB ? -1 : 1
    if (indexA) return -1
    return 1
  })
}
